<script>
import utilsMixin from '@shared/mixins/utils';
import communityMixin, { commentItem } from '@shared/mixins/community';
import BaseAvatar from '@shared/components/BaseAvatar.vue';
import ListPostCommentForm from '@shared/components/Community/ListPostCommentForm.vue';
import CommentItemContextMenu from '@shared/components/Community/CommentItemContextMenu.vue';
// import UploadedMediaCard from '@shared/components/UploadedMediaCard.vue';

export default {
  mixins: [utilsMixin, communityMixin('comment'), commentItem],
  components: {
    BaseAvatar,
    ListPostCommentForm,
    CommentItemContextMenu,
  },
};
</script>

<template>
  <article class="card">
    <div class="card-content p-3">
      <div class="media">
        <div class="media-left mr-2">
          <BaseAvatar
            :avatar="comment.owner.avatar"
            :displayName="displayName"
          />
        </div>
        <div class="media-content">
          <p class="has-text-weight-bold is-size-6 is-size-8-mobile">
            {{ displayName }}
          </p>
          <p class="subtitle is-9">
            <router-link
              class="has-text-dark"
              :to="{
                name: 'community_group_post',
                params: {postUUID: post.uuid},
                query: {comment: comment.uuid},
              }"
            >
              <b-tooltip>
                <template #content>
                  Publiée <br>
                  le {{ comment.published_at | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
                </template>
                {{ comment.published_at | momentFromUTC | moment('from') }}
              </b-tooltip>
            </router-link>
          </p>
        </div>
        <div class="media-right">
          <CommentItemContextMenu
            :post="post"
            :parent="parent"
            :comment="comment"
            :isChild="isChild"
            :hasReadonlyOption="false"
            :hasNotificationOption="false"
            :canManageCommunityEntity="canManageCommunityEntity"
            @edit="isEditing = true"
          />
        </div>
      </div>
      <ListPostCommentForm
        v-if="isEditing"
        :post="post"
        :parentUUID="parent && parent.uuid"
        :comment="comment"
        @update="isEditing = false"
        @cancel="isEditing = false"
      />
      <div v-else class="content break-words" v-html="content" />
      <!-- <UploadedMediaCard v-if="comment.media.url" :media="comment.media" /> -->
    </div>
  </article>
</template>
