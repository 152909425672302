var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"btop-grey"},[(_vm.comments.data.length > 0)?_c('div',{staticClass:"px-3 py-4"},[(_vm.comments.links && _vm.comments.links.next)?_c('p',{staticClass:"mb-3"},[_c('b-button',{staticClass:"tdecoration-underline",attrs:{"type":"is-text","size":"is-small"},on:{"click":function($event){return _vm.$emit('load-comments', {
          parent: null,
          url: _vm.comments.links.next,
          append: true
        })}}},[_vm._v(" Voir les commentaires précédents ")])],1):_vm._e(),_vm._l((_vm.comments.data),function(comment){return _c('CardCommentItem',{key:comment.uuid,attrs:{"post":_vm.post,"comment":comment},scopedSlots:_vm._u([{key:"childrenPagination",fn:function(ref){
        var links = ref.links;
return [(links && links.next)?_c('p',[_c('b-button',{staticClass:"tdecoration-underline",attrs:{"type":"is-text","size":"is-small"},on:{"click":function($event){return _vm.$emit('load-comments', {
              parent: comment,
              url: links.next,
              append: true
            })}}},[_vm._v(" Voir plus de commentaires ")])],1):_vm._e()]}}],null,true)},[(
          !(comment.settings && comment.settings.readonly)
          && !_vm.postCommentsReadonly
        )?_c('CardCommentForm',{attrs:{"post":_vm.post,"parentUUID":comment.uuid}}):_vm._e()],1)})],2):_vm._e(),(!_vm.postCommentsReadonly)?_c('div',{staticClass:"media p-3"},[_c('div',{staticClass:"media-left"},[_c('figure',{staticClass:"image is-32x32"},[_c('img',{staticClass:"is-rounded w-full h-full ofit-cover",attrs:{"src":_vm.$store.getters['community/userAvatar'],"alt":_vm.$store.getters['community/userDisplayName']}})])]),_c('div',{staticClass:"media-content"},[_c('CardCommentForm',{attrs:{"post":_vm.post}})],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }