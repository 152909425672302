<script>
import { mapActions } from 'vuex';
import CommunityAbuseForm from '@shared/components/CommunityAbuseForm.vue';

export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
    parent: {
      type: Object,
      default: null,
    },
    comment: {
      type: Object,
      required: true,
    },
    isChild: {
      type: Boolean,
      required: true,
    },
    hasReadonlyOption: {
      type: Boolean,
      default: true,
    },
    hasNotificationOption: {
      type: Boolean,
      default: true,
    },
    canManageCommunityEntity: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    ...mapActions('community', [
      'deleteComment',
      'toggleSubscriptionComment',
      'toggleReadonlyComment',
    ]),
    toggleSubscription() {
      const load = this.$buefy.loading.open({ container: this.$refs.list });
      this.toggleSubscriptionComment({
        postUUID: this.post.uuid,
        parentUUID: this.parent?.uuid,
        comment: this.comment,
      })
        .finally(() => (load.close()));
    },
    toggleReadonly() {
      const load = this.$buefy.loading.open({ container: this.$refs.list });
      this.toggleReadonlyComment({
        postUUID: this.post.uuid,
        parentUUID: this.parent?.uuid,
        comment: this.comment,
      })
        .finally(() => (load.close()));
    },
    report() {
      let modal = this.$buefy.modal.open({
        parent: this,
        component: CommunityAbuseForm,
        canCancel: false,
        hasModalCard: true,
        props: {
          context: 'comment',
          contextUUID: this.comment.uuid,
        },
        events: {
          cancel() {
            modal.close();
            modal = null;
          },
          done() {
            modal.close();
            modal = null;
          },
        },
      });
    },
    editComment() {
      this.$emit('edit');
    },
    deleteCommentDialog() {
      this.$buefy.dialog.confirm({
        type: 'is-danger',
        title: 'Action définitive',
        message: 'Êtes-vous sûr(e) de vouloir supprimer cette réponse ?',
        focusOn: 'cancel',
        confirmText: 'Oui',
        closeOnConfirm: false,
        onConfirm: (_, { close }) => {
          const loader = this.$buefy.loading.open();
          this.deleteComment({
            postUUID: this.post.uuid,
            parentUUID: this.parent?.uuid,
            commentUUID: this.comment.uuid,
          })
            .then(() => {
              close();
              this.$showMessage.success();
            })
            .finally(() => loader.close());
        },
      });
    },
  },
};
</script>

<template>
  <b-dropdown position="is-bottom-left">
    <template #trigger>
      <b-button
        class="px-0 has-text-black30"
        type="is-text"
        size="is-small"
        icon-left="ellipsis-v"
      />
    </template>
    <b-dropdown-item
      v-if="hasNotificationOption && !isChild"
      @click="toggleSubscription"
    >
      <template v-if="comment.subscribed">
        <b-icon pack="far" icon="bell-slash" />
        Ne plus recevoir de notifications pour cette réponse
      </template>
      <template v-else>
        <b-icon pack="far" icon="bell" />
        Recevoir les notifications pour cette réponse
      </template>
    </b-dropdown-item>
    <b-dropdown-item
      v-if="
        hasReadonlyOption && (
          !(post.settings && post.settings.readonly)
          && !isChild && canManageCommunityEntity
        )
      "
      @click="toggleReadonly"
    >
      <template v-if="comment.settings && comment.settings.readonly">
        <b-icon icon="comment" />
        Activer les réponses
      </template>
      <template v-else>
        <b-icon icon="comment-slash" />
        Désactiver les réponses
      </template>
    </b-dropdown-item>
    <b-dropdown-item @click="report">
      <b-icon pack="far" icon="flag" />
      Signaler
    </b-dropdown-item>
    <template v-if="canManageCommunityEntity">
      <b-dropdown-item separator />
      <b-dropdown-item
        @click="editComment"
      >
        <b-icon icon="pen" />
        Modifier
      </b-dropdown-item>
      <b-dropdown-item
        class="has-text-danger"
        @click="deleteCommentDialog"
      >
        <b-icon icon="trash" />
        Supprimer
      </b-dropdown-item>
    </template>
  </b-dropdown>
</template>
