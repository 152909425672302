<script>
import { mapMutations } from 'vuex';
import APIGroup from '@shared/services/API/Community/Group';
import { genericForms } from '@shared/mixins/forms';

export default {
  mixins: [genericForms],
  props: {
    context: {
      type: String,
      required: true,
    },
    contextUUID: {
      type: String,
      required: true,
    },
  },
  form: {
    reason: {
      label: 'Quelle est la raison du signalement ?',
      type: 'select',
      column: 'is-12',
      options: {
        SPAM: 'Spam / Contenu indésirable',
        DISCRIMINATION: 'Discrimination (racisme, religieux...)',
        HATE: 'Propos haineux',
        VIOLENCE: 'Comportement violent',
      },
      inputAttrs: {
        required: true,
      },
    },
    description: {
      label: 'Ajoutez un commentaire à votre signalement',
      type: 'textarea',
      column: 'is-12',
      inputAttrs: {
        maxlength: 500,
        required: true,
      },
    },
  },
  data: () => ({
    newAbuse: {
      reason: '',
      description: '',
    },
  }),
  methods: {
    ...mapMutations('community', [
      'incrementGroupCounter',
    ]),
    action() {
      const data = { ...this.newAbuse };

      return (
        this.context === 'comment'
          ? APIGroup.reportComment(this.contextUUID, data)
          : APIGroup.reportPost(this.contextUUID, data)
      )
        .then(() => {
          this.$emit('done');
          this.incrementGroupCounter('abuses_count');
        });
    },
  },
};
</script>

<template>
  <AppCardModal
    tag="form"
    hasCloseBtn
    @close="$emit('cancel')"
    @submit.prevent="dataIsValid(isLoading) && handle()"
  >
    <template #title>
      Signaler
    </template>
    <template #body>
      <div class="columns is-multiline">
        <div
          v-for="(field, k) in $options.form"
          :key="k"
          class="column mb-0"
          :class="field.column"
        >
          <GeneratorField
            :fieldName="k"
            :fieldDefinition="field"
            :formData.sync="newAbuse"
          />
        </div>
      </div>
    </template>
    <template #foot>
      <b-button type="is-text" @click="$emit('cancel')">
        Annuler
      </b-button>
      <b-button
        type="is-primary is-custom"
        native-type="submit"
        :loading="isLoading"
      >
        Envoyer
      </b-button>
    </template>
  </AppCardModal>
</template>
