<script>
import { mapActions, mapMutations } from 'vuex';
import utilsMixin from '@shared/mixins/utils';
import CommunityAbuseForm from '@shared/components/CommunityAbuseForm.vue';

export default {
  mixins: [utilsMixin],
  props: {
    canManageCommunityEntity: {
      type: Boolean,
      required: true,
    },
    post: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapMutations('community', [
      'showPostForm',
    ]),
    ...mapActions('community', [
      'togglePinPost',
      'toggleSubscriptionPost',
      'toggleReadonlyPost',
      'deletePost',
    ]),
    togglePin() {
      const load = this.$buefy.loading.open({ container: this.$refs.list });
      this.togglePinPost({ post: this.post })
        .finally(() => (load.close()));
    },
    toggleSubscription() {
      const load = this.$buefy.loading.open({ container: this.$refs.list });
      this.toggleSubscriptionPost({ post: this.post })
        .finally(() => (load.close()));
    },
    toggleReadonly() {
      const load = this.$buefy.loading.open({ container: this.$refs.list });
      this.toggleReadonlyPost({ post: this.post })
        .finally(() => (load.close()));
    },
    deletePostDialog() {
      this.showAsyncConfirm({
        message: `
          Êtes-vous sûr(e) de vouloir supprimer cette publication&nbsp;?
          <strong>Toutes réponses et médias associés seront définitivement supprimés</strong>.
        `,
      }, ({ done }) => {
        const load = this.$buefy.loading.open();
        return this.deletePost({ uuid: this.post.uuid })
          .then(() => {
            if (this.$route.name === 'community_group_post') {
              this.$router.push({ name: 'community_group_posts' });
            }

            done();
          })
          .finally(() => load.close());
      });
    },
    report() {
      let modal = this.$buefy.modal.open({
        parent: this,
        component: CommunityAbuseForm,
        canCancel: false,
        hasModalCard: true,
        props: {
          context: 'post',
          contextUUID: this.post.uuid,
        },
        events: {
          cancel() {
            modal.close();
            modal = null;
          },
          done() {
            modal.close();
            modal = null;
          },
        },
      });
    },
  },
};
</script>

<template>
  <b-dropdown position="is-bottom-left">
    <template #trigger>
      <b-button
        class="px-0"
        type="is-text"
        size="is-small"
        icon-left="ellipsis-v"
      />
    </template>
    <b-dropdown-item
      v-if="isStoreAdmin"
      @click="togglePin"
    >
      <b-icon icon="thumbtack" />
      <template v-if="post.pinned">
        Enlever l'épingle
      </template>
      <template v-else>
        Épingler en tête des discussions
      </template>
    </b-dropdown-item>
    <b-dropdown-item @click="toggleSubscription">
      <template v-if="post.subscribed">
        <b-icon pack="far" icon="bell-slash" />
        Ne plus recevoir de notifications pour cette publication
      </template>
      <template v-else>
        <b-icon pack="far" icon="bell" />
        Recevoir les notifications pour cette publication
      </template>
    </b-dropdown-item>
    <b-dropdown-item
      v-if="canManageCommunityEntity"
      @click="toggleReadonly"
    >
      <template v-if="post.settings && post.settings.readonly">
        <b-icon icon="comment" />
        Activer les réponses
      </template>
      <template v-else>
        <b-icon icon="comment-slash" />
        Désactiver les réponses
      </template>
    </b-dropdown-item>
    <b-dropdown-item @click="report">
      <b-icon pack="far" icon="flag" />
      Signaler
    </b-dropdown-item>
    <template v-if="canManageCommunityEntity">
      <b-dropdown-item separator />
      <b-dropdown-item @click="showPostForm({ post })">
        <b-icon icon="pen" />
        Modifier
      </b-dropdown-item>
      <b-dropdown-item
        class="has-text-danger"
        @click="deletePostDialog"
      >
        <b-icon icon="trash" />
        Supprimer
      </b-dropdown-item>
    </template>
  </b-dropdown>
</template>
