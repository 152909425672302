<script>
import utilsMixin from '@shared/mixins/utils';
import VueSimpleEmbed from '@shared/components/VueSimpleEmbed.vue';

export default {
  mixins: [utilsMixin],
  components: { VueSimpleEmbed },
  props: {
    media: {
      type: Object,
      required: true,
    },
  },
};
</script>

<template>
  <figure
    v-if="media.type === 'image'"
    key="image"
    class="image"
  >
    <img
      :src="media.url"
      alt="image"
      :width="media.width"
      :height="media.height"
    >
  </figure>
  <figure
    v-else-if="media.type === 'video'"
    key="video"
    class="image"
  >
    <vue-plyr v-if="media.source != 'embed'">
      <video
        :src="media.url"
        :width="media.width"
        :height="media.height"
      />
    </vue-plyr>
    <VueSimpleEmbed
      v-else
      :url="media.url"
      parser="plyr"
    />
  </figure>
  <figure
    v-else-if="media.type === 'audio'"
    key="audio"
    class="box p-0 image"
  >
    <vue-plyr v-if="media.source != 'embed'">
      <audio :src="media.url" />
    </vue-plyr>
    <VueSimpleEmbed
      v-else
      :url="media.url"
    />
  </figure>
  <figure
    v-else-if="media.type === 'resource'"
    key="resource"
    class="box py-2 pl-4 pr-2 image flex-between-center"
  >
    Pièce jointe ({{ media.size | formatSizeToMB }} Mo)
    <a
      class="button is-custom ml-5"
      :href="media.url"
      target="_blank"
      rel="noreferrer noopener"
      download="download"
    >
      Télécharger
    </a>
  </figure>
</template>
