<script>
export default {
  props: {
    size: {
      type: [String, Number],
      default: 48,
    },
    avatar: {
      type: String,
      default: null,
    },
    imgClass: {
      type: String,
      default: '',
    },
    isRounded: {
      type: Boolean,
      default: true,
    },
    displayName: {
      type: String,
      required: true,
    },
  },
  computed: {
    src() {
      if (this.avatar) {
        return this.avatar.startsWith('http')
          ? this.avatar
          : `${this.$env.apiResourcesURL}/${this.avatar}`;
      }

      const index = this.displayName[0].toLowerCase().charCodeAt(0) % 24;

      // eslint-disable-next-line
      return `${this.$env.apiDomain}/img/avatars/${index + 1}.png`;
    },
  },
};
</script>

<template>
  <figure class="image" :class="`is-${size}x${size}`">
    <img
      class="w-full h-full ofit-cover"
      :class="[imgClass, {'is-rounded': isRounded}]"
      :src="src"
      :alt="displayName"
    />
  </figure>
</template>
