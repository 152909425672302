<script>
import utilsMixin from '@shared/mixins/utils';
import { commentForm } from '@shared/mixins/community';
import TiptapEditor from '@shared/components/TiptapEditor.vue';

export default {
  mixins: [utilsMixin, commentForm],
  components: { TiptapEditor },
};
</script>

<template>
  <form
    class="is-relative"
    @submit.prevent="dataIsValid(isCommentPosting) && handle()"
  >
    <b-loading
      v-if="isCommentPosting"
      :is-full-page="false"
      active
    />

    <div class="field">
      <TiptapEditor
        ref="newComment"
        placeholder="Votre réponse"
        :value="newComment.content"
        @input="newComment.content = $event"
      />
    </div>
    <div class="buttons is-right">
      <b-button
        v-if="comment"
        class="tdecoration-underline is-size-9"
        type="is-text"
        :loading="isCommentPosting"
        @click="$emit('cancel')"
      >
        Annuler
      </b-button>
      <b-button
        native-type="submit"
        type="is-primary is-custom"
        size="is-small"
        :loading="isCommentPosting"
      >
        <template v-if="comment">
          Modifier
        </template>
        <template v-else>
          Répondre
        </template>
      </b-button>
    </div>
  </form>
</template>
